import React, {useState} from 'react'
import styled from 'styled-components'
import GlobalStyle from '../utils/GlobalStyle'

import Button from '../components/elements/Button'

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 60px; 
  background: #F8F8F8;
  color: black;
  ${({dark}) =>  dark &&`
    background: black;
  `}
`

const BtnWrap = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`

const Debug = () => {

  const [dark, setDark] = useState(true)

  return (
    <Wrap dark={dark}>
      <GlobalStyle />
      <BtnWrap>
        <Button onClick={() => setDark(!dark)}  text='Primary' />
        <Button disabled text='Primary' />
      </BtnWrap>
      <BtnWrap>
        <Button secondary text='Secondary' />
        <Button disabled secondary text='Secondary' />
      </BtnWrap>
      <BtnWrap>
        <Button line text='Line button' />
        <Button disabled line text='Line button' />
      </BtnWrap>
      <BtnWrap>
        <Button lineAlt text='Line Alt button' />
        <Button disabled lineAlt text='Line Alt button' />
      </BtnWrap>
      <BtnWrap>
        <Button tertiary text='Tertiary' />
        <Button disabled tertiary text='Tertiary' />
      </BtnWrap>
      <BtnWrap>
        <Button txt text='Text link' />
        <Button disabled txt text='Text link' />
      </BtnWrap>
      <BtnWrap>
        <Button footer text='Footer link' />
        <Button disabled footer text='Footer link' />
      </BtnWrap>
      <BtnWrap>
        <Button menu text='Menu link' />
        <Button disabled menu text='Menu link' />
      </BtnWrap>
      <BtnWrap>
        <Button menuAlt text='Menu Alt link' />
        <Button disabled menuAlt text='Menu Alt link' />
      </BtnWrap>
    </Wrap>
  )
}

export default Debug
