import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    min-width: 320px;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
  }

  a {
    text-decoration: none;
  }

  a:active {
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @keyframes show {
    from {
      top: -83px;
    }
    to {
      top: 0
    }
  }

  @keyframes hide {
    from {
      top: 0
    }
    to {
      top: -83px;
    }
  }
`

export default GlobalStyle
