import styled, { css } from 'styled-components'

// Media Queries

export const sizes = {
  tablet: 1000,
  phone: 768
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

// Media utils

export const hideMixin = css`
  ${({ hidedesktop }) => hidedesktop && css`
    @media (min-width: ${sizes.tablet}px) {
      display: none;
    }
  `}
  ${({ hidetablet }) => hidetablet && css`
    @media (max-width: ${sizes.tablet}px) AND (min-width: ${sizes.phone}px) {
      display: none;
    }
  `}
  ${({ hidephone }) => hidephone && css`
    @media (max-width: ${sizes.phone}px) {
      display: none;
    }
  `}
`

// Structural components

export const Container = styled.div`
  display: block;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  position: relative;
  z-index: 1000;
  ${({ fluid }) => fluid && `
    max-width: 100%;
  `}
  ${media.tablet`
    padding: 0 12px;
    max-width: 768px;
  `}
`

export const Justify = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ align }) => align && `
    align-items: ${align};
  `}
  ${media.phone`
    ${({ phoneColumn }) => phoneColumn && `
      flex-direction: column;
    `}
    ${({ phoneAlignStart }) => phoneAlignStart && `
      align-items: flex-start;
    `}
  `}
`
