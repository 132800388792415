import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery } from 'gatsby'
import { media, hideMixin } from '../Structure'
import Img from 'gatsby-image'

const Wrap = styled.button`
  background-color: #796FFF;
  color: white;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  padding: 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.08);
  cursor: pointer;
  border: none;
  transition: color .3s ease, background-color .3s ease;
  &:hover {
    background-color: #6058cc;
  }
  &:active {
    background-color: #484299;
  }
  &:disabled {
    cursor: not-allowed;
    color: #D8D8D8;
    background-color: #eee;
  }
  ${hideMixin}
  ${({ secondary }) => secondary && `
    color: #796FFF;
    background: white;
    &:hover {
      background-color: #f7f7f7;
    }
    &:active {
      background-color: #eeeeee;
    }
    &:disabled {
      color: #E4E4E4;
      background-color: #f5f5f5;
    }
  `}
  ${({ line }) => line && `
    background-color: transparent;
    color: #796FFF;
    box-shadow: none;
    border: solid 1px;
    &:hover {
      background: rgba(121,111,255,0.12);
      color: #6058cc;
    }
    &:active {
      background: rgba(121,111,255,0.2);
      color: #484299;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
    }
  `}
  ${({ lineAlt }) => lineAlt && `
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: solid 1px;
    &:hover {
      background: rgba(255,255,255,0.25);
      color: white;
    }
    &:active {
      background: rgba(255,255,255,0.35);
      color: white;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
    }
  `}
  ${({ tertiary }) => tertiary && `
    background-color: transparent;
    color: #796FFF;
    box-shadow: none;
    padding: 0;
    &:hover {
      background: none;
      color: #6058cc;
    }
    &:active {
      background: none; 
      color: #484299;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
    }
  `}
  ${({ menu }) => menu && `
    color: #796FFF;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 35px;
    box-shadow: none;
    &:hover {
      background: none;
      color: #6058cc;
    }
    &:active {
      background: none; 
      color: #484299;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
    }
  `}
  ${({ menuAlt }) => menuAlt && `
    color: white;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 35px;
    box-shadow: none;
    &:hover {
      background: none;
      color: white;
      box-shadow: 0 2px 0 white;
    }
    &:active {
      background: none; 
      color: white;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
    }
  `}
  ${({ txt }) => txt && `
    background-color: transparent;
    color: #796FFF;
    box-shadow: none;
    padding: 0;
    font-size: 17px;
    text-decoration: underline;
    font-weight: 400;
    text-transform: none;
    &:hover {
      background: none;
      color: #000;
    }
    &:active {
      background: none; 
      color: #484299;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
      text-decoration: none;
    }
  `}
  ${({ footer }) => footer && `
    background-color: transparent;
    color: #000;
    box-shadow: none;
    padding: 0;
    font-size: 17px;
    font-weight: 400;
    text-transform: none;
    transition: none;
    &:hover {
      background: none;
      color: #796FFF;
      text-decoration: underline;
    }
    &:active {
      background: none; 
      color: #484299;
    }
    &:disabled {
      color: #E7E7E7;
      border-color: #F0F0F0;
      background-color: transparent;
      text-decoration: none;
    }
  `}
  ${({ hm }) => hm && `
    margin: 0 10px;
  `}
  ${media.tablet`
    ${({ tabletBlock }) => tabletBlock && `
      display: block;
      width: 100%;
      margin: 15px 0;
    `}
  `}
  ${media.phone`
    ${({ phoneBlock }) => phoneBlock && `
      display: block;
      width: 100%;
      margin: 10px 0;
    `}
  `}
`

const Icon = styled(Img)`
    position: relative;
    top: 3px;
    left: 10px;
    transform: scale(.75);
`

const Button = (props) => {
  const iconArrow = useStaticQuery(queryIconArrow)

  const { text, tertiary, link } = props

  const Btn = (
    <Wrap {...props}>
      {text}
      {tertiary && <Icon fixed={iconArrow.file.childImageSharp.fixed} />}
    </Wrap>
  )

  return link ? (
    link.includes('http') || link.includes('mailto') ? (
      <a href={link} target="_blank">
        {Btn}
      </a>
    ) : (
      <Link to={link} children={Btn} />
    )
  ) : Btn
}

const queryIconArrow = graphql`
  query {
    file(relativePath: { eq: "icon_arrow.png" }) {
      childImageSharp {
        fixed(width: 16, height: 16) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }  
`

export default Button
